var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", "min-width": "20" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "项目名", prop: "projectName", "min-width": "60" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "环境", prop: "env", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "集群", prop: "cluster", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "详情", type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "demo-table-expand",
                        attrs: { "label-position": "left", inline: "" },
                      },
                      [
                        _c("el-form-item", { attrs: { label: "配置内容：" } }, [
                          _c("span", [_vm._v(_vm._s(scope.row.content))]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "校验Schema：" } },
                          [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.validationSchema)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "灰度比例(%)", prop: "ratio", "min-width": "30" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "格式", prop: "format", "min-width": "30" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建者", prop: "sourceUser", "min-width": "30" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", "min-width": "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("timestampFormat")(scope.row.createdTime))
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-edit",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.rollback(scope.row)
                          },
                        },
                      },
                      [_vm._v("回滚\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }